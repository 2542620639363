import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  toggle() {
    const input = this.inputTarget;

    if (this.inputTarget.classList.contains('hidden')) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
      input.value = ''
    }
  }
}
